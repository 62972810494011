import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Layout from "../components/layout"
import { Box, Button, Typography, useMediaQuery } from "@mui/material"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import { Pagination } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"

import "swiper/css"
import "swiper/css/pagination"

import FeaturedResource from "../components/featured-resources/FeaturedResource"
import Link from "../components/Link"
import ContactCard from "../components/ContactCard"
import Empty from "../components/Empty"

const MainArticle = ({
  strapi_id,
  header: { image, title },
  description: { data: { description } } = {},
}) => {
  return (
    <Box
      mt={{ xs: 1, lg: 9 }}
      display="flex"
      flexDirection="row"
      gap={{ xs: 2.5, lg: 11 }}
      flexWrap="wrap"
    >
      <Box
        display="flex"
        flexDirection="column"
        gap={2.5}
        flex={{ xs: "1 100%", lg: 1 }}
        order={{ xs: 2, lg: 1 }}
      >
        <Box>
          <Typography variant="overline" fontWeight={500} color="primary.main">
            Featured news articles
          </Typography>

          <Typography variant="h1">{title}</Typography>
        </Box>

        <Typography variant="body1" color="text.secondary">
          {
            description
            // .split("\n")
            // .filter(substring => substring !== "")[0]
            // .split(". ")
            // .splice(0, 2)
            // .join(". ") + "..." || ""
          }
        </Typography>

        <Link href={`/articles/${strapi_id}`}>
          <Button
            aria-label="Read more"
            variant="contained"
            sx={({ palette: { primary } }) => ({
              bgcolor: primary.light,
              color: primary.main,
              "&:hover": { bgcolor: primary.light },
            })}
          >
            Read more
          </Button>
        </Link>
      </Box>

      <Box
        flex={{ xs: "1 100%", lg: 1 }}
        sx={{ "*": { borderRadius: 3, height: 1 } }}
        order={{ xs: 1 }}
      >
        <GatsbyImage objectFit="cover" image={getImage(image.localFile)} />
      </Box>
    </Box>
  )
}

const getSwiperStyles = ({ palette }) => ({
  ".swiper": {
    marginLeft: -4,
  },
  ".swiper-pagination": {
    bottom: "0px !important",
  },
  ".swiper-pagination-bullet-active": {
    bgcolor: palette.primary.main,
  },

  ".featured-resource": {
    mr: { xs: 0, md: 4 },
  },
})
const FeaturedArticles = ({ articles }) => {
  const [page, setPage] = React.useState(2) // first two rows are visible by default

  const isLgUp = useMediaQuery(theme => theme.breakpoints.up("xl"))
  const isMdUp = useMediaQuery(theme => theme.breakpoints.up("md"))

  if (articles?.length === 0) return <Empty />

  const loadMore = () => setPage(page => page + 1)

  return (
    <Box width={1} maxWidth={1} display="flex" flexDirection="column" gap={4}>
      {new Array(page).fill(0).map((e, i) => (
        <Box
          maxWidth={1}
          key={e + i}
          sx={theme => ({ ...getSwiperStyles(theme) })}
        >
          <Swiper
            modules={[Pagination]}
            slidesPerView={isLgUp ? 3 : isMdUp ? 2 : 1}
            pagination
          >
            {articles.slice(i * 3, i * 3 + 3).map((resource, index) => (
              <SwiperSlide key={resource.strapi_id || index}>
                <FeaturedResource resourceURI="/articles" {...resource} />
              </SwiperSlide>
            ))}
          </Swiper>
        </Box>
      ))}

      {articles.slice(page * 3).length !== 0 && (
        <Button
          aria-label="Load more"
          variant="text"
          onClick={loadMore}
          size="large"
          sx={({}) => ({
            alignSelf: "center",
            padding: "24px 80px !important",
            boxShadow: "4px 4px 20px rgba(7, 41, 56, 0.08)",
            boxSizing: "border-box",
            borderRadius: 1.5,
            maxWidth: 270,
          })}
        >
          Load More
        </Button>
      )}
    </Box>
  )
}

const NewsPage = () => {
  const {
    allStrapiArticle: { nodes: articles },
  } = useStaticQuery(newsQuery)

  return (
    <Layout>
      <Box
        display="flex"
        flexDirection="column"
        gap={{ xs: 7, md: 12.5 }}
        width={1}
      >
        <MainArticle {...articles[0]} />
        <ContactCard />
      </Box>

      <FeaturedArticles articles={[...articles.slice(1)]} />
    </Layout>
  )
}

const newsQuery = graphql`
  query NewsQuery {
    allStrapiArticle(sort: { fields: publishedAt, order: DESC }) {
      nodes {
        strapi_id
        readTime
        publishedAt
        header {
          title
          subtitle
          image {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
            url
          }
        }

        description {
          data {
            description
          }
        }
      }
    }
  }
`

export default NewsPage
