import React from "react"
import { Box, Button, Typography } from "@mui/material"

import topImage from "../images/contactCardTop.svg"
import bottomImage from "../images/contactCardBottom.svg"

import Link from "./Link"

export const ContactCard = ({
  content = "Interested in learning more about us?",
  href = "/contact",
  sx = {},
}) => {
  return (
    <Link href={href} sx={sx}>
      <Box
        borderRadius={2}
        py={{ xs: 2, md: 7 }}
        px={{ xs: 2, md: 5 }}
        bgcolor="primary.light"
        position="relative"
        overflow="hidden"
        maxWidth={1}
      >
        <Box maxWidth={{ xs: 1, sm: 0.7 }}>
          <Typography variant={"h5"} mb={3}>
            {content}
          </Typography>

          <Button
            aria-label="Contact us"
            variant="contained"
            sx={{
              padding: { xs: "14.5px, 24px", sm: "14.5px 32px" },
              fontSize: "1rem",
              svg: {
                display: { xs: "none", sm: "inline-block" },
                ml: 0.5,
                mb: -0.5,
              },
            }}
          >
            Contact us
          </Button>
        </Box>
        <Box
          display={{ xs: "none", md: "block" }}
          position="absolute"
          right={0}
          top={0}
        >
          <img src={topImage} />
        </Box>
        <Box
          position="absolute"
          right={{ xs: 0, md: "10%" }}
          bottom={{ xs: 0, md: -10 }}
        >
          <img src={bottomImage} />
        </Box>
      </Box>
    </Link>
  )
}

export default ContactCard
